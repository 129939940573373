.googleButton {
  height: 40px;
  display: flex;
  margin-top: 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  border: 1px solid silver;
}
.butText {
  color: #5b6268;
  font-weight: 500;
}
.googleLogo {
  width: 20px;
  width: 20px;
  margin-right: 10px;
}
